<template>
    <div id="template" class="relative sm:w-full md:w-full lg:w-full mx-auto self-center">
        <div class="header flex">
            <div class="back flex items-center justify-center mr-auto" @click="back">
                <feather-icon icon="ArrowLeftIcon"></feather-icon>
            </div>
            <div class="title flex items-center uppercase">New data</div>
            <div class="save flex items-center justify-center ml-auto" @click.prevent="save">
                <feather-icon icon="CheckIcon"></feather-icon>
            </div>
        </div>
        <div class="body sm:w-full md:w-full lg:w-full mx-auto self-center">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Route" v-model="form.route"
                        :danger="validation.hasError('form.route')" val-icon-danger="clear"
                        :danger-text="validation.firstError('form.route')" />
                </div>
                <div class="vx-col w-full mt-2 flex">
                    Ex : https://ceritabandung.com/about
                </div>
            </div>
           
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Meta Title" v-model="form.title_en"
                        :danger="validation.hasError('form.title_en')" val-icon-danger="clear"
                        :danger-text="validation.firstError('form.title_en')" />
                </div>
            </div>
            
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Meta Description" v-model="form.description_en"
                        :danger="validation.hasError('form.description_en')" val-icon-danger="clear"
                        :danger-text="validation.firstError('form.description_en')" />
                </div>
            </div>
            <div class="vx-row mb-4">
                <div class="vx-col w-full">
                    <vs-button class="w-full" icon-pack="feather" icon="icon-check" @click.prevent="save">
                        Simpan</vs-button>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-button class="w-full" type="flat" color="dark" @click="back" icon-pack="feather"
                        icon="icon-arrow-left">
                        Kembali</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import SimpleVueValidation from 'simple-vue-validator'
    const Validator = SimpleVueValidation.Validator

    Vue.use(SimpleVueValidation)
    export default {
        data() {
            return {
                form: {
                    route: '',
                    title_en: '',
                    description_en: '',
                },
            }
        },
        validators: {
            'form.route': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value).required()
                }
            },
            'form.title_en': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value).required()
                }
            },
            'form.description_en': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value).required()
                }
            },
        },
        
        methods: {
            back() {
                this.$router.go(-1)
            },
            save() {
                this.$validate().then(result => {
                    if (result) {
                        this.actionSave().then(async () => {
                                this.$vs.notify({
                                    title: 'Success!',
                                    text: 'Success!',
                                    color: 'success',
                                    position: 'top-center'
                                })
                                await this.clearForm();
                                this.validation.reset();
                            })
                            .catch(err => {
                                this.$vs.notify({
                                    title: 'Oops!',
                                    text: err.response ? err.response.data.message :
                                        'Something wrong, ' + err,
                                    color: 'danger',
                                    position: 'top-center'
                                })
                                this.$vs.loading.close()
                            })
                    }
                })
            },
            actionSave() {
                return new Promise((resolve, reject) => {
                    const postData = {
                        route: this.form.route,
                        title_en: this.form.title_en,
                        description_en: this.form.description_en,
                    }
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/en/meta',
                            method: 'POST',
                            data: postData
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            clearForm() {
               this.form.route = ""
               this.form.title_en = ""
               this.form.description_en = ""
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/vuesax/_variables.scss";

    #template {
        min-height: 400px;
        background-color: #ffffff;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, .05);
        border-radius: .5rem;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .header {
            padding-top: 20px;
            margin-bottom: 40px;

            .help-toggle {
                background: rgba(var(--vs-primary), 1) !important !important;

                span {
                    font-size: 16px;
                    color: rgba(var(--vs-primary), 1) !important !important;
                }
            }

            .back {
                width: 40px;
                height: 40px;
                background: rgba(0, 0, 0, .05);
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .title {
                color: #444444;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }

            .save {
                width: 40px;
                height: 40px;
                background: rgba(var(--vs-primary), 1) !important;
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    color: $white;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .body {
            .now {
                font-size: 25px;
                text-align: center;
                margin-top: 20%;
            }

            .error-msg {
                color: $danger;
                font-size: 11px
            }

            .icons {
                width: 100%;

                li {
                    width: 40px;
                    height: 40px;
                    border-radius: 3px;
                    border: 1px solid transparent;
                    transition: all .5s;
                    cursor: pointer;

                    .feather-icon {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        background: rgb(255, 255, 255);
                        border: 1px solid rgb(248, 249, 250);
                        box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .05);
                    }

                    &.active {
                        background: rgba(var(--vs-primary), 1) !important;

                        .feather-icon {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
</style>